'use strict';

import $ from 'jquery';

export default class TabsRteRevamp {
	constructor($selector, selfInit = true) {
    this.$selector = $selector;
    this.state = {
			isAnimating: false
		};
    this.$tabs = $('.content-tabs__nav div', this.$selector);

    if(selfInit) this.init();
  }

	init() {
		this.$tabs.map((i,ele) => {
      $(ele).find('a').on('click', (e) => {
        e.preventDefault();

        const target = $(ele).find('a').attr('href');

        if(!$(ele).hasClass('active') && !this.state.isAnimating) {
          $('.content-tabs__nav div', this.$selector).removeClass('active');
          $(ele).addClass('active');

          this.state.isAnimating = !this.state.isAnimating;

          $(`[aria-hidden="false"]`, this.$selector).fadeOut(300, () => {
            $(`[aria-hidden="false"]`, this.$selector).attr('aria-hidden', true);
            $(`${target}`, this.$selector).attr('aria-hidden', false);
            $(`${target}`, this.$selector).fadeIn(300, () => {
              this.state.isAnimating = !this.state.isAnimating;
            });
          });
        }
      });
    });
  }
}

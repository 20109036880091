'use strict';

export default class RadioButton {
  constructor($selector, selfInit = true) {
    this.$selector = $selector;
    this.name = $('input', this.$selector).attr('name');
    this.value = $('input', this.$selector).val();
    this.label = $('label', this.$selector).text();

    if(selfInit) this.init();
  }

  init() {
    $('input', this.$selector).on('change', (e) => {
      emitter.emit('updateData', { key: this.name, value: this.value, type: 'radio' });
    });

    emitter.on('radioClear', () => {
      this.$selector.find('input').prop('checked', false);
    });

    emitter.on('updateRadioUI', (data) => {
      this.updateUI(data);
    });
  }

  updateUI(data) {
    if(this.name == data.key && this.value == data.value ) {
      this.$selector.find('input').prop('checked', true);
    }
  }
}

'use strict';

import { initCarousel } from "../../../_scripts/helpers";

export default class ProgrammeCarousel {
  constructor($selector, selfInit = true) {
    this.$selector = $selector;
    this.carousel = null;

    if(selfInit) this.init();
  }

  init() {
    // on load
    if(this.carousel === null) {
      this.carousel = new initCarousel($('[data-carousel]', this.$selector));
    }
  }
}

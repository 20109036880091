'use strict';

import EventTimelineItem from "../../molecules/event-timeline-item/event-timeline-item";

export default class EventTimeline {
  constructor($selector, selfInit = true) {
    this.$selector = $selector;

    if(selfInit) this.init();
  }

  init() {
    $('details', this.$selector).map((i, ele) => {
      new EventTimelineItem(ele);
    });

    $('.event-timeline-item', this.$selector).first().find('summary').trigger('click');

    let siteHeaderHeight = $('.site-header').outerHeight();

    emitter.on('changeEventTab', (data) => {
      if(!$(`.event-timeline-group#${data.value}`, this.$selector).length) {
        $('.event-timeline__events', this.$selector).animate({
          scrollTop: 0
        });
      }
      else {
        if (typeof $(`.event-timeline-group#${data.value} .event-timeline-item`, this.$selector).first().attr('open') == 'undefined' && $(`.event-timeline-group#${data.value} .event-timeline-item`, this.$selector).first().attr('open') !== true) {
          $(`.event-timeline-group#${data.value} .event-timeline-item`, this.$selector).first().find('summary').trigger('click');
        }

        //Calculate the position of the target element relative to the container's current scroll position
        var relativeOffset = $(`.event-timeline-group#${data.value}`, this.$selector).offset().top - $('.event-timeline__events', this.$selector).offset().top + + $('.event-timeline__events', this.$selector).scrollTop();
        $('.event-timeline__events', this.$selector).animate({
          scrollTop: relativeOffset - 20
        }, () => {
          $("html, body").animate({
            scrollTop: $(`.event-timeline-group#${data.value}`, this.$selector).offset().top - siteHeaderHeight - 50
          }); 
        }); 

        
      }
    });
  }
}

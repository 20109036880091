import Carousel from "../_modules/carousel/carousel";

export function initCarousel(
  $carousel = $("[data-carousel]", this.$selector),
  options,
  slickInit = true,
  init = null,
  before = null,
  after = null,
  setPos = null,
  callback = null
) {
  const carousel = new Carousel(
    $carousel,
    true,
    options,
    init,
    before,
    after,
    setPos,
    slickInit
  );

  if (callback) callback();

  return carousel;
}
'use strict';

import $ from 'jquery';
import doT from 'dot';

export default class CourseFinderWidget {
	constructor($selector) {
		const _self = this;
		const $courseFinderAttr = $selector.data('index'),
			$courseTable = $('.course-finder__table', $selector),
			$courseContent = $('.course-finder__table--content', $selector),
			$searchBtn = $('.course-finder__search-button', $selector);
			
		this.$searchCourseInput = $('#text', $selector);
		this.$courseTable = $courseTable;
		this.$courseContent = $courseContent;
		this.$switchValue = $('.switch > input[type="checkbox"]', $selector);
		this.data = JSON.parse($('#courseFinderData', this.$selector).html());
		this.searchEndpoint = $selector.data('searchEndpoint');
		this.paceURL = $selector.data('paceurl') ? $selector.data('paceurl') : 'https://www.sp.edu.sg/pace/courses/all-courses';

		$searchBtn.on('click', (e) => {
			e.preventDefault();
			
			const textValue = $('#text').val();

			this.submitSearch(textValue);
		});

		let learnersTypeList = [];
			
		this.data = JSON.parse($('#courseFinderData', this.$selector).html());
		
		for(let i = 0; i < this.data.length; i++){
			if(learnersTypeList.indexOf(this.data[i].learnersType) < 0){
				learnersTypeList.push(this.data[i].learnersType);
			}
		}

		if($courseFinderAttr != '') {
			_self.selectByText($courseFinderAttr);
			this.renderResults(this.data);
		}

		// // user presses enter in search
		this.$searchCourseInput.on('keyup', e => {
			if (e.keyCode == 13 || e.which == 13) {
				e.preventDefault();

				const textValue = $('#text').val();

				this.submitSearch(textValue);
			}

			if(this.$switchValue.data('value') == 'Students') {
				this.renderResults(this.data);
			}
		});

		// // user selects switch
		this.$switchValue.on('change', () => {
			const oldValue = this.$switchValue.data('value');
			if (oldValue == 'Students') {
				this.$switchValue.data('value', 'Adults');
			} else {
				this.$switchValue.data('value', 'Students');
			}

			this.renderResults(this.data);
		});
	}

	submitSearch(textValue) {
		let searchUrl;

		if(this.$switchValue.data('value') == 'Adults') {
			searchUrl = `${this.paceURL}?catalogue_search=${textValue}&page=1&per_page=12`;
		}
		else {
			searchUrl = this.searchEndpoint + '?query=' + textValue;
		}

		window.location.href = searchUrl; 
	}
    
	selectByText(data) {
		$('#learnersType option').filter(function() { 
			// return $.trim($(this).data('list')) == data;
			return $.trim($(this).text()) == data;
		}).attr('selected', true);
	}

	renderResults(data) {
		let parameters = {
			textValue: $('#text').val(),
			learnersTypeValue: this.$switchValue.data('value')
		}

		let firstFilter = [];		// filter list based on learnersType

		for(let i = 0; i < data.length; i++){
			if(parameters.learnersTypeValue === 'all') {
				firstFilter = data;
			}
			else {
				if(data[i].learnersType === parameters.learnersTypeValue){
					firstFilter.push(data[i]);
				}
			}
		}

		// console.log(secondFilter);

		const courseTemplate = $('#dotCourseFinder').html();
		let courseContent = '';

		courseContent = doT.template(courseTemplate)(firstFilter);
		
		this.$courseContent.html(courseContent);
	}
}

'use strict';

import dot from "dot";

import Checkbox from "../../molecules/checkbox/checkbox";
import RadioButton from "../../molecules/radio-button/radio-button";
import FilterSelectionList from "../../molecules/filter-selection-list/filter-selection-list";
import ListingSearchBar from "../../molecules/listing-search-bar/listing-search-bar";
import ListingFilter from "../listing-filter/listing-filter";
import Pagination from "../../molecules/pagination/pagination";

export default class CoursesListingRevamp {
  constructor($selector, selfInit = true) {
    this.$selector = $selector;
    this.endpoint = this.$selector.data('endpoint');
    this.$template = $('#dotCoursesCard').html();
    this.itemsPerPage = $(window).width() > 1024 ? 12 : 6;

    this.data = {
      query: '',
      type: $(`input[type="radio"][name="type"]:checked`, this.$selector).length ? $(`input[type="radio"][name="type"]:checked`, this.$selector).val() : '',
      category: '',
      page: 1,
      itemsPerPage: this.itemsPerPage
    };

    this.$pagination = $('.pagination', this.$selector);
    this.pagination = new Pagination(this.$pagination);

    if(selfInit) this.init();
  }

  init() {
    // module setup
    new ListingFilter($('.listing-filter', this.$selector));
    this.filterSelection = new FilterSelectionList($('.filter-selection-list', this.$selector));

    $('.listing-search-bar', this.$selector).map((i, ele) => {
      new ListingSearchBar($(ele));
    });

    $('.radio-button', this.$selector).map((i, ele) => {
      new RadioButton($(ele));
    });

    $('.checkbox', this.$selector).map((i, ele) => {
      new Checkbox($(ele));
    });

    // set up filter ui based on query parameters in URL
    this.getUrlQueries();
    this.updateFilterUI();

    // listener for any filter value change
    emitter.on('updateData', (query) => {
      this.data.page = 1;

      if(query.type == 'checkbox') {
        if(this.data[query.key].indexOf(query.value) < 0) {
          const valueArr = this.data[query.key].length ? this.data[query.key].split('|') : [];
          valueArr.push(query.value);
          
          this.data[query.key] = valueArr.join('|');
        }
        else {
          const valueArr = this.data[query.key].split('|');
          const indexToRemove = valueArr.indexOf(query.value);
          valueArr.splice(indexToRemove, 1);
          
          this.data[query.key] = valueArr.join('|');
        }
      }
      else {
        this.data[query.key] = query.value;

        if(query.type == 'search') {
          this._updateData();
        }

        if(query.type == 'radio') {
          // reset aggregated checkbox value
          this.data.category = '';

          if(!$(`#${query.value}`).hasClass('active')) {
            $('.listing-filter__field--checkboxes.active').fadeOut(300, () => {
              //uncheck all checked checkboxes in current active, then remove active class
              $('.listing-filter__field--checkboxes.active').find('input[type="checkbox"]:checked').prop('checked', false);

              $('.listing-filter__field--checkboxes.active').removeClass('active');

              // clear selection pills
              emitter.emit('selectionClear');

              // set target to be active
              $(`#${query.value}`).addClass('active');
              $(`#${query.value}`).fadeIn(300);
            });
          }
        }
      }
    });

    emitter.on('selectionRemoved', (data) => {
      const valueArr = this.data[data.name].split('|');
      const indexToRemove = valueArr.indexOf(data.value);
      valueArr.splice(indexToRemove, 1);
      
      this.data[data.name] = valueArr.join('|');

      $(`input[type="checkbox"][value="${data.value}"]`, this.$selector).prop('checked', false);
    });

    emitter.on('fetchData', () => {
      this._updateData();
    });

    /* Render listing */
    this._updateData = () => {
      const callback = (res) => {
        this._renderTemplate(res);

        if (res.items.length == 0) {
          this.$pagination.hide();
        } else {
          this.$pagination.show();
          this._renderPagination(res.totalPages);
        }
      };

      this.updateData(callback);
      this.updateURL();
    };

    //first call to retrieve data
    this._updateData();

    window.onpopstate = (e) => {
      this.getUrlQueries();
      this.updateFilterUI();
      this._updateData();
    };
  }

  updateSummary(totalItems) {
    if (parseInt(totalItems) === 0) {
      $('.courses-listing__summary .range', this.$selector).text(`0 - 0`);
    }

    if (parseInt(totalItems) !== 0) {
      const start = (this.data.page - 1) * this.itemsPerPage + 1;
      const end = this.data.page * this.itemsPerPage > totalItems ? totalItems : this.data.page * this.itemsPerPage;

      $('.courses-listing__summary .range', this.$selector).text(`${start} - ${end}`);
    }

    $('.courses-listing__summary .total', this.$selector).text(totalItems);
  }

  _renderTemplate(data) {
    const dotTemplate = dot.template(this.$template)(data.items);

    $('.courses-listing__results__body', this.$selector).html(dotTemplate);

    this.updateSummary(data.totalItems);
  }

  _renderPagination(totalpages) {
    let callback = () => {
      this.$pagination.find('a').map((i, ele) => {
        $(ele).on('click', e => {
          e.preventDefault();
          this.data.page = $(ele).data('page');

          $("html, body").animate({
            scrollTop: $('.courses-listing__summary', this.$selector).offset().top - 140,
          });

          this._updateData();
        });
      });
    };

    this.pagination.update(this.data.page, totalpages, callback);
  }

  updateData(callback) {
    $.ajax({
      url: this.endpoint,
      data: JSON.stringify(this.data),
      method: 'post',
      contentType: 'application/json',
      success: (res) => {
        callback(res);
      },
      error: (err) => {
        console.log(err);

        if (this.$selector.data("errormsg")) {
          $('.courses-listing__results__body', this.$selector).html(`<p class="empty-msg">${this.$selector.data("errormsg")}</p>`);
        }
      }
    });
  }

  updateURL(replaceState = false) {
    const searchParams = new URLSearchParams(this.data);

    if (replaceState) {
      window.history.replaceState(this.data, "", `?${searchParams.toString()}`);
    } else {
      window.history.pushState(this.data, "", `?${searchParams.toString()}`);
    }
  }

  getUrlQueries() {
    const query = Object.fromEntries(new URLSearchParams(window.location.search));

    for (const key in query) {
      this.data[key] = query[key];
    }
  }

  updateFilterUI() {
    emitter.emit('updateSearchBarUI', { value: this.data.query, key: 'query' });
    emitter.emit('updateSelectUI', { value: this.data.year, key: 'year' });

    emitter.emit('updateRadioUI', { value: this.data.type, key: 'type' });

    if(!$(`#${this.data.type}`).hasClass('active')) {
      $('.listing-filter__field--checkboxes.active').fadeOut(300, () => {
        //uncheck all checked checkboxes in current active, then remove active class
        $('.listing-filter__field--checkboxes.active').find('input[type="checkbox"]:checked').prop('checked', false);

        $('.listing-filter__field--checkboxes.active').removeClass('active');

        // set target to be active
        $(`#${this.data.type}`).addClass('active');
        $(`#${this.data.type}`).fadeIn(300);
      });
    }
    
    emitter.emit('selectionClear');
    emitter.emit('checkboxClear');

    const checkboxValues = this.data.category.split("|");
    for(let i = 0; i < checkboxValues.length; i++) {
      emitter.emit('updateCheckboxUI', { value: checkboxValues[i], key: 'category' });
    }

    emitter.emit('updateSwitchUI', { value: this.data.sortby, key: 'sortby' });
  }
}

'use strict';

import $ from 'jquery';

export default class SquareCard {
	constructor($selector) {
		$selector.on('click', function() {
			if ($(this).find('a').attr('href')) {
				var url = $(this).find('a').attr('href');
				window.open(url); 
			}
		});
	}
}

'use strict';

export default class AccordionGroup {
  constructor($selector, selfInit = true) {
    this.$selector = $selector;
    this.isExpanded = false;

    if(selfInit) this.init($selector);
  }

  init() {
    const $controlBtn = $('.accordion-group__control button', this.$selector);
    $controlBtn.on('click', (e) => {
      if (this.isExpanded) {
        this.$selector.removeClass('active');
        $('.accordion', this.$selector).map((i, ele) => {
          $(ele).find('.accordion__content').slideUp(300);
          $(ele).removeClass('expanded');
        });
        $controlBtn.text('Expand all');
      } else {
        this.$selector.addClass('active');
        $('.accordion', this.$selector).map((i, ele) => {
          $(ele).find('.accordion__content').slideDown(300);
          $(ele).addClass('expanded');
        });
        $controlBtn.text('Collapse all');
      }
      
      this.isExpanded = !this.isExpanded;
    });

    emitter.on('accordionExpand', () => {
      //Check if all accordion are expanded, if yes, set control button to collapse all
      let isAllExpanded = true;
      for (let i = 0; i < $('.accordion', this.$selector).length; i++) {
        if (!$($('.accordion', this.$selector).get(i)).hasClass('expanded')) {
          isAllExpanded = false;
          break;
        }
      }

      if (isAllExpanded) {
        this.$selector.addClass('active');
        $controlBtn.text('Collapse all');
        this.isExpanded = true;
      }
    });

    emitter.on('accordionCollapse', () => {
      //Check if all accordions were originally expanded, if yes, set control button from collapse all to expand all
      let isAccordionExpanded = false;
      for (let i = 0; i < $('.accordion', this.$selector).length; i++) {
        if (!$($('.accordion', this.$selector).get(i)).hasClass('expanded')) {
          isAccordionExpanded = true;
          break;
        }
      }

      if (isAccordionExpanded) {
        if (this.$selector.hasClass('active')) {
          this.$selector.removeClass('active');
          $controlBtn.text('Expand all');
          this.isExpanded = false;
        }
      } 
    });
  }
}

'use strict';

import $ from 'jquery';

export default class Toggle {
	constructor($selector) {
		let $leftText = $('.left-text', $selector),
				$rightText = $('.right-text', $selector),
				$toggleBtn = $('.switch');
		
		$('input[type="checkbox"]', $selector).prop('checked', false); //Set initial value to not checked
		$toggleBtn.on('change', (e) => {
			$leftText.toggleClass('active');
			$rightText.toggleClass('active');
			e.stopPropagation();
	});
	}
}

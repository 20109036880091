'use strict';

import $ from 'jquery';

export default class CuratedInfoWidget {
	constructor($selector, selfInit = true) {
		this.$selector = $selector;

		if(selfInit) this.init();
	}

	init() {
		const $curatedInfoList = $('.curated-info-widget__list', this.$selector),
			$curatedInfoItem = $('.curated-info-widget__item', this.$selector),
			$curatedInfoCta = $('.curated-info-widget__cta', this.$selector),
			$curatedInfoLinks = $('.curated-info-widget__links', this.$selector),
			$selectField = $('select', this.$selector),
			$customList = $('.custom-list', this.$selector);
		
		$curatedInfoCta.hide();

		// user clicks on options in 1st page
		$curatedInfoItem.on('click', function(e) {
			e.preventDefault();

			// ui
			$curatedInfoCta.show();
			$curatedInfoList.hide();

			// when user clicks, set the selected option 
			let clickedCategory = $(e.target).data('category');

			// set active class to render
			$curatedInfoLinks.filter(function() {
				return $(this).data('category') === clickedCategory;
			}).addClass('active');

			//CUSTOM SELECT
			$selectField.val(clickedCategory);
			$selectField.trigger('change');
		});

		// user clicks on select dropdown in 2nd page
		$('.custom-select', this.$selector).on('click', '.custom-list button', (e) => {
			$curatedInfoLinks.removeClass('active');
			let clickedCategory = $(e.currentTarget).parent().data('value');

			// set active class to render
			$curatedInfoLinks.filter(function() {
				return $(this).data('category') === clickedCategory;
			}).addClass('active');
		});

		$('button', $customList).map((i, ele) => {
			$(ele).on('click', e => {
				$curatedInfoLinks.removeClass('active');

				// set active class to render
				$curatedInfoLinks.filter(function() {
					return $(this).data('category') === clickedCategory;
				}).addClass('active');
			});
		});
	}
}

'use strict';

export default class ExpandableHoverCard {
  constructor($selector, selfInit = true) {
    this.$selector = $selector;
    if(selfInit) this.init($selector);
  }

  init($selector) {
    const $expandContent = $('.expandable-hover-card__expand-content', $selector);

    emitter.on('closeExpandableHoverCard', ()=> {
      this.closeCard();
    })

    $($selector).on('click', (e) => {
      if (!$selector.hasClass('open')) {
        e.preventDefault();  
        $('.expandable-hover-card', $($selector.parent().parent()[0])).removeClass('open');
        $('.expandable-hover-card', $($selector.parent()[0])).not($selector);
        this.assignHeight();
        
        setTimeout(()=>{
          $expandContent.scrollTop(0); //Set scroll position to the top of the expand content
          $selector.addClass('open');
        }, 300);
      }
    });

    $('.expandable-hover-card__close-btn', $selector).on('click', (e) => {
      e.stopPropagation();
      this.closeCard();
    });

    //In mobile view, when user scroll to the bottom end of the expand-content, close off the expand content
    $expandContent.on('scroll', (e)=> {
      let scrollTop = $expandContent.scrollTop();
      let scrollHeight = $expandContent.prop('scrollHeight');
      let clientHeight = $expandContent.innerHeight();

      if (scrollTop + clientHeight >= (scrollHeight - 10)) {
          $selector.removeClass('open');
      }
    });
  }

  assignHeight() {
    let height = this.$selector.outerHeight();

    this.$selector.css({
      'height': height + 'px'
    });
  }

  closeCard() {
    this.$selector.css({
      'height': 'auto'
    });

    this.$selector.removeClass('open');
  }
}
'use strict';

export default class Accordion {
  constructor($selector, $contentClassName = '.accordion__content', selfInit = true) {
    this.$selector = $selector;
    this.$contentClassName = $contentClassName;

    if(selfInit) this.init();
  }

  init() {
    $('button', this.$selector).on('click', (e) => {
      e.preventDefault();

      if(!this.$selector.hasClass('expanded')){
        this.open();
        emitter.emit('openAccordion');
      }
      else {
        this.close();
      }
    });

    const hash = window.location.hash;
    if ($(hash).length) {
      if ($(hash, this.$selector).length) {
        //If there's an anchor id in URL and the anchor point is present in the accordion, open the accordion and scroll to the anchor point
        this.open();

        setTimeout(() => {
          $("html, body").animate({
            scrollTop: $(hash).offset().top - $(".site-header").outerHeight() - 20,
          }).delay(300);
        },300);
      }
    }
  }

  close() {
		this.$selector.find(this.$contentClassName).slideUp(300);
		this.$selector.removeClass('expanded');
    emitter.emit('accordionCollapse');
	}

	open() {
		this.$selector.find(this.$contentClassName).slideDown(300);
		this.$selector.addClass('expanded');
    emitter.emit('accordionExpand');
	}
}

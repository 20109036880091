'use strict';

export default class HoverableImageCard {
  constructor($selector, selfInit = true) {
    if(selfInit) this.init($selector);
  }

  init($selector) {
    if (this.isTouchDevice()) {
      console.log("This is a touch screen device.");
      // Add your touch-specific code here
    } else {
        console.log("This is not a touch screen device.");
        // Add your non-touch-specific code here
    }

    $($selector).on('click', (e) => {
      if(this.isTouchDevice()) {
        if (!$selector.hasClass('open')) {
          e.preventDefault();
          $('.hoverable-image-card', $($selector.parent().parent()[0])).removeClass('open');
          $selector.addClass('open');
        } else {
          $selector.removeClass('open');
        }
      }
    });
  }

  isTouchDevice() {
    return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0)) &&
    ('ontouchstart' in document.documentElement);
  }
}

'use strict';

export default class Checkbox {
  constructor($selector, selfInit = true) {
    this.$selector = $selector;
    this.name = $('input', this.$selector).attr('name');
    this.value = $('input', this.$selector).val();
    this.label = $('label', this.$selector).text();

    if(selfInit) this.init();
  }

  init() {
    $('input', this.$selector).on('change', (e) => {
      if($('input', this.$selector).is(':checked')) {
        emitter.emit('filterSelected', { value: this.value, label: this.label, name: this.name });
      }
      else {
        emitter.emit('filterRemoved', { value: this.value });
      }

      emitter.emit('updateData', { key: this.name, value: this.value, type: 'checkbox' });
    });

    emitter.on('checkboxClear', () => {
      this.$selector.find('input').prop('checked', false);
    });

    emitter.on('updateCheckboxUI', (data) => {
      this.updateUI(data);
    });
  }

  updateUI(data) {
    if(this.name == data.key && this.value == data.value ) {
      this.$selector.find('input').prop('checked', true);

      emitter.emit('filterSelected', { value: this.value, label: this.label, name: this.name });
    }
  }
}

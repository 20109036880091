'use strict';

export default class SwitchToggle {
  constructor($selector, selfInit = true) {
    this.$selector = $selector;
    this.state = {
      name: '',
      values: []
    }

    if(selfInit) this.init();
  }

  init() {
    this.state.name = $('input[type="radio"]', this.$selector).attr('name');

    $('input[type="radio"]', this.$selector).map((i, ele) => {
      // init: populate values
      this.state.values.push($(ele).val());
    });

    emitter.emit('setSortBy', { key: this.state.name, value: $(`input[name="${this.state.name}"]:checked`, this.$selector).val(), type: 'switch' });

    // bind click event for UI toggle to switch values
    $('.switch-toggle__toggle', this.$selector).on('click', () => {
      if($(`input[name="${this.state.name}"]:checked`, this.$selector).val() == this.state.values[0]) {
        $(`input[name="${this.state.name}"][value="${this.state.values[1]}"]`, this.$selector).prop('checked', true);
      }
      else { 
        $(`input[name="${this.state.name}"][value="${this.state.values[0]}"]`, this.$selector).prop('checked', true);
      }

      emitter.emit('updateData', { key: this.state.name, value: $(`input[name="${this.state.name}"]:checked`, this.$selector).val(), type: 'switch' });
    });

    emitter.on('updateSwitchUI', (data) => {
      this.updateUI(data);
    });
  }

  updateUI(data) {
    $(`input[name="${data.key}"][value="${data.value}"]`, this.$selector).prop('checked', true);
  }
}

'use strict';

import dot from 'dot';

export default class ModalHandler {
  constructor(selfInit = true) {
    this.modalTemplate = $('#electiveModal').html();
    if(selfInit) this.init();
  }

  init() {
    $('body').on('click', '[data-modal]', (e) => {
      e.preventDefault();

      let _modalHTML = $($('[data-modal-content]', $(e.currentTarget))[0]).clone();

      $('.modal-content').html(_modalHTML);
      this.openModal();
    });

    $('.modal-content', '#portal').on('click', (e) =>{
      e.stopPropagation()
    });

    $('#portal').on("click", () => {
      this.closeModal();
    });

    $('#portal .modal-ui button').on('click', (e) => {
      e.preventDefault();
  
      this.closeModal();
    });

    $(document).on('keyup', (e) => {
      
      if (e.keyCode == 27 || e.which == 27) {
        this.closeModal();
      }
    });
  }

  openModal() {
    $('body').addClass('no-scroll');
    $('#portal').fadeIn(300);
  }

  closeModal() {
    $('.modal-content').empty();
    $('body').removeClass('no-scroll');
    $('#portal').fadeOut(300);
  }
}

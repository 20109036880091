'use strict';

import $ from 'jquery';

export default class SchoolCoursesWidget {
	constructor($selector) {
		this.$selector = $selector;
				
		if ($selector.hasClass('school-courses-widget--carousel')) {
			let $courseList = $('.school-courses-widget__body', $selector);
			
			const arrowLeftPng = `<span class="icon icon-left-arrow"></span>`;
			const arrowRightPng = `<span class="icon icon-right-arrow"></span>`;
			
			$courseList.slick({
				speed: 700,
				swipe: true,
				slidesToShow: 4,
				slidesToScroll: 1,
				prevArrow: `<button class="slick-prev carousel-btn carousel-btn__prev" type="button">${arrowLeftPng}<span class="sr-only">navigation button</span></button>`,
				nextArrow: `<button class="slick-next carousel-btn carousel-btn__next" type="button">${arrowRightPng}<span class="sr-only">navigation button</span></button>`,
				infinite: true,
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 2
						}
					},
					{
						breakpoint: 767,
						settings: {
							slidesToShow: 1
						}
					}
				]
			})
		}
	}
}

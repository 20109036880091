'use strict';

export default class ThreeCardSection {
  constructor($selector, selfInit = true) {
    this.$selector = $selector;
    this.$media = $('.three-cards-section__image picture', $selector);

    if(selfInit) this.init($selector);
  }

  init() {
    this.setMinHeight();
    let resizeTimer;
    $(window).on('resize', () => {
      clearTimeout(resizeTimer);

      resizeTimer = setTimeout(() => {
        this.setMinHeight();
      }, 300);
    });
  }

  setMinHeight() {
    if ($(window).width() >= 1024) {
      //Set minimum height to ensure picture element will not overflow from content height
      const imgHeight = this.$media.outerHeight();
      this.$selector.css("min-height", `${imgHeight}px`);
    }
  }
}

'use strict';

export default class ListingSearchBar {
  constructor($selector, selfInit = true) {
    this.$selector = $selector;

    if(selfInit) this.init();
  }

  init($selector) {
    $('input', this.$selector).on('keyup', (e) => {
      if (e.keyCode == 13 || e.which == 13) {
        e.preventDefault();

        emitter.emit('updateData', { key: 'query', value: $('input', this.$selector).val(), type: 'search' });
      }
    });

    $('button', this.$selector).on('click', (e) => {
      e.preventDefault();

      emitter.emit('updateData', { key: 'query', value: $('input', this.$selector).val(), type: 'search' });
    });

    emitter.on('updateSearchBarUI', (data) => {
      this.updateUI(data);
    });
  }

  updateUI(data) {
    $('input', this.$selector).val(decodeURIComponent(data.value));
  }
}

'use strict';

import $ from 'jquery';
import Emitter from 'tiny-emitter';

export default class CustomSelect {
  /* REQUIREMENT:
  - tiny-emitter is required (https://www.npmjs.com/package/tiny-emitter)
  - check HTML structure in custom-select.pug
  - initialise by calling new CustomSelect($wrapper)
  */
  constructor($selector) {
    this.isShown = false;
    this.selectedOption = {};

    // TO FIX: custom icon to be available
    let $triggerButton = $('<button class="custom-trigger" type="button"><span class="label"></span><span class="icon icon-chevron-down"></span></button>');

    this.$selector = $selector;
    this.$select = $('select', $selector);
    this.$customDropdown = $('<div class="custom-select__custom-dropdown"></div>');
    this.$triggerLabel = $triggerButton.find('.label');
    this.$customList = $('<ul class="custom-list"></ul>');

    this.name = this.$select.attr('name');

    $triggerButton.appendTo(this.$customDropdown);

    this.$select.find('option').map((i, option) => {
      const $option = $(option);

      // by default 1st option is selected
      if (i == 0) {
        this.selectedOption['value'] = $option.val();
        this.selectedOption['name'] = $option.text();
      }

      // overwrite the selected option defined earlier
      if ($option.attr('selected')) {
        this.selectedOption['value'] = $option.val();
        this.selectedOption['name'] = $option.text();
      }

      // Create custom list
      const $customOption = $(`<li data-value="${ $option.val() }"></li>`);
      const $optionButton = $(`<button type="button">${ $option.text() }</button>`);

      $optionButton.appendTo($customOption);
      $customOption.appendTo(this.$customList);

      $optionButton.on('click', e => {
        this.selectedOption['value'] = $option.val();
        this.selectedOption['name'] = $option.text();
        this.updateDropdown();
        this.updateSelectField();
        this.hideList();
        this.$select.trigger('change');
      });
    });

    this.updateDropdown();
    this.updateSelectField();

    this.$customList.appendTo(this.$customDropdown);
    this.$customDropdown.appendTo($selector);

    this.$select.on('change.customSelect', e => {
      if (this.$select.val() == null) {
        // to set default of value to be first option of the dropdown
        this.selectedOption['value'] = this.$select.find('option').eq(0).val();
        this.selectedOption['name'] = this.$select.find('option').eq(0).text();
      } else {
        this.selectedOption['value'] = this.$select.val();
        this.selectedOption['name'] = this.$select.find(`option[value="${ this.$select.val() }"]`).text();
      }
      this.updateDropdown();

      if(this.$selector.parent().hasClass('listing-filter__field--select')) {
        emitter.emit('updateData', { key: this.name, value: this.$select.val(), type: 'select' });
      }

      if(this.$selector.parents().hasClass('event-timeline')) {
        emitter.emit('changeEventTab', { key: this.name, value: this.$select.val(), type: 'select' });
      }
    });

    if(this.$selector.parent().hasClass('listing-filter__field--select')) {
      emitter.emit('setYear', { key: this.name, value: this.$select.val(), type: 'select' });
    }

    $triggerButton.on('click', e => {
      e.preventDefault();

      if (this.isShown) {
        this.hideList();
      } else {
        window.emitter.emit('selectOpened', this.$selector);
        this.showList();
      }
    });

    // to close the select when other is being opened
    window.emitter.on('selectOpened', ($openedSelector) => {
      if (this.$selector != $openedSelector) {
        this.hideList();
      }
    });

    emitter.on('updateSelectUI', (data) => {
      this.updateUI(data);
    });

    $(document).on('click.customFilter', e => {
      let $eTarget = $(e.target);

      if (!$eTarget.hasClass('custom-select__custom-dropdown') && !$eTarget.parents('.custom-select__custom-dropdown').length) {
        this.hideList();
      }
    });
  }

  showList() {
    this.isShown = true;
    this.$customDropdown.addClass('list-shown');
    this.$selector.addClass('selecting');
  }

  hideList() {
    this.isShown = false;
    this.$customDropdown.removeClass('list-shown');
    this.$selector.removeClass('selecting');
  }

  updateDropdown() {
    this.$customList.find('.active').removeClass('active');
    this.$customList.find(`[data-value="${ this.selectedOption.value }"]`).addClass('active');
    this.$triggerLabel.text(this.selectedOption.name);
  }

  updateSelectField() {
    this.$select.val(this.selectedOption.value);
  }

  getSelectedValue() {
    return this.selectedOption['value'];
  }

  updateUI(data) {
    this.selectedOption['value'] = data.value;
    this.selectedOption['name'] = $(`option[value="${data.value}"]`, this.$selector).text();
    this.updateDropdown();
    this.updateSelectField();
  }
}

'use strict';

import $ from 'jquery';
import 'slick-carousel';

export default class HeroBannerWidget {
	constructor($selector) {
		let $heroBannerCarousel = $('.hero-banner-widget__carousel', $selector);

			const arrowLeftPng = `<span class="icon icon-left-arrow"></span>`;
			const arrowRightPng = `<span class="icon icon-right-arrow"></span>`;

		$heroBannerCarousel.slick({
			dots: true,
			infinite: true,
			speed: 700,
			swipe: true,
			fade: true,
			prevArrow: `<button class="slick-prev carousel-btn carousel-btn__prev" type="button">${arrowLeftPng}<span class="sr-only">navigation button</span></button>`,
			nextArrow: `<button class="slick-next carousel-btn carousel-btn__next" type="button">${arrowRightPng}<span class="sr-only">navigation button</span></button>`,
			autoplay: true,
			autoplaySpeed: 5000,
			pauseOnHover: true,
			slidesToShow: 1,
			slidesToScroll: 1,
		});

		// on tablet, width is shared. carousel unable to set width, have to refresh once
		if ($(window).width() > 768 && $(window).width() < 1023){
			$heroBannerCarousel.slick("refresh");
		}
	}
}

'use strict';

import $ from 'jquery';

export default class TableResponsive {
	constructor($selector, selfInit = true) {
		this.$selector = $selector;
		
		if(selfInit) this.init();
	}

	init() {
		$(window).on('resize.tableResponsive', () => {
			if (!this.$selector.closest('.table-responsive').length) {
				if (this.$selector.width() > this.$selector.parent().width()) {
					this.$selector.wrap('<div class="table-responsive"></div>');
				}
			}
		}).trigger('resize.tableResponsive');

		window.emitter.on('openAccordion', () => {
			
		});
	}
}

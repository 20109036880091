// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

// npm module imports
// To remove if not used
import 'jquery';
import Emitter from 'tiny-emitter';
import TableResponsive from '../_modules/table-responsive/table-responsive';
import BackToTop from '../_modules/atoms/back-to-top/back-to-top';

// home page
import Toggle from '../_modules/molecules/toggle/toggle';

// dev module imports
// common imports
import CustomSelect from '../_modules/molecules/custom-select/custom-select';
import HeroBannerWidget from '../_modules/organisms/hero-banner-widget/hero-banner-widget';
import NewTestimonialWidget from '../_modules/organisms/new-testimonial-widget/new-testimonial-widget';
import CuratedInfoWidget from '../_modules/organisms/curated-info-widget/curated-info-widget';
import CourseFinderWidget from '../_modules/organisms/course-finder-widget/course-finder-widget';
import HappeningsCarousel from '../_modules/organisms/happenings-carousel/happenings-carousel';
import SubNav from '../_modules/molecules/sub-nav/sub-nav';
import HoverableImageCard from '../_modules/molecules/hoverable-image-card/hoverable-image-card';
import ExpandableHoverCard from '../_modules/molecules/expandable-hover-card/expandable-hover-card';

// landing pages
import SquareCard from '../_modules/atoms/square-card/square-card';
import AboutWidgetSquareCard from '../_modules/atoms/about-widget-square-card/about-widget-square-card';
import SchoolCard from '../_modules/atoms/school-card/school-card';
import DiplomaCard from '../_modules/atoms/diploma-card/diploma-card';

import SchoolCoursesWidget from '../_modules/organisms/school-courses-widget/school-courses-widget';
import NewsListingRevamp from '../_modules/organisms/news-listing-revamp/news-listing-revamp';
import CoursesListingRevamp from '../_modules/organisms/courses-listing-revamp/courses-listing-revamp';
import NewsArticleListing from '../_modules/organisms/news-article-listing/news-article-listing';
import ElectiveListing from '../_modules/organisms/elective-listing/elective-listing';
import EventListing from '../_modules/organisms/event-listing/event-listing';
import HappeningsListing from '../_modules/organisms/happenings-listing/happenings-listing';
import Carousel from '../_modules/carousel/carousel';
import ProgrammeCarousel from '../_modules/organisms/programme-carousel/programme-carousel';
import AccordionGroup from '../_modules/organisms/accordion-group/accordion-group';
import Accordion from '../_modules/organisms/accordion/accordion';
import TabsRteRevamp from '../_modules/organisms/tabs-rte-revamp/tabs-rte-revamp';
import VideoSection from '../_modules/organisms/video-section/video-section';
import VideoPlayer from '../_modules/atoms/video-player/video-player';
import TabbedTables from '../_modules/organisms/tabbed-tables/tabbed-tables';
import ModalHandler from '../_modules/molecules/modal-handler/modal-handler';
import SiteHeader from '../_modules/organisms/site-header/site-header';
import CardWithWhiteText from '../_modules/molecules/card-with-white-text/card-with-white-text';
import EventTimeline from '../_modules/organisms/event-timeline/event-timeline';
import SchoolSearch from '../_modules/molecules/school-search/school-search';
import ThreeCardSection from '../_modules/organisms/3-card-section/3-card-section';
import TestimonialList from '../_modules/organisms/testimonial-list/testimonial-list';

$(() => {
  // set vh unit to browser
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);

	window.addEventListener("resize", () => {
  	vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
	});

	//To emit events
  window.emitter = new Emitter();

	// Apply wrapper for table
  if($('table').length) {
    $('table').map((i,ele) => {
      new TableResponsive($(ele));
    });
  }

	new BackToTop();

  if ($('.site-header').length) {
    $('.site-header').map((i, ele) => {
      new SiteHeader($(ele));
    })
	}
  
  // home page
  if ($('.hero-banner-widget').length) {
    $('.hero-banner-widget').map((i, ele) => {
      new HeroBannerWidget($(ele));
    });
	}

	if ($('.curated-info-widget').length) {
    $('.curated-info-widget').map((i, ele) => {
      new CuratedInfoWidget($(ele));
    })
	}

	if ($('.toggle').length) {
    $('.toggle').map((i, ele) => {
      new Toggle($(ele));
    })
	}
	
	if ($('.course-finder').length) {
    $('.course-finder').map((i, ele) => {
      new CourseFinderWidget($(ele));
    })
	}
  
  if ($('.new-testimonial-widget').length) {
    $('.new-testimonial-widget').map((i, ele) => {
      new NewTestimonialWidget($(ele));
    })
	}

  if ($('.happenings-carousel').length) {
    $('.happenings-carousel').map((i, ele) => {
      new HappeningsCarousel($(ele));
    });
	}

  // landing pages
  if($('.square-card').length){
    $('.square-card').map((i, ele) => {
      new SquareCard($(ele));
    });
	}

	if($('.about-widget-square-card').length){
    $('.about-widget-square-card').map((i, ele) => {
      new AboutWidgetSquareCard($(ele));
    });
	}

	if($('.school-card').length){
    $('.school-card').map((i, ele) => {
      new SchoolCard($(ele));
    });
	}

	if($('.diploma-card').length){
    $('.diploma-card').map((i, ele) => {
      new DiplomaCard($(ele));
    });
	}

  if($('[data-carousel]').length) {
    $('[data-carousel]').map((i,ele) => {
      const selfInit = $(ele).data('noinit') ? false : true;

      new Carousel($(ele), selfInit, null, null, null);
    });
  }

  if($('.programme-carousel').length) {
    $('.programme-carousel').map((i,ele) => {
      new ProgrammeCarousel($(ele));
    });
  }

  if($('.news-article-listing--tabbed').length) {
    $('.news-article-listing--tabbed').map((i, ele) => {
      new NewsArticleListing($(ele));
    })
  }

  if($('.news-listing').length) {
    $('.news-listing').map((i, ele) => {
      new NewsListingRevamp($(ele));
    })
  }

  if($('.courses-listing').length) {
    $('.courses-listing').map((i, ele) => {
      new CoursesListingRevamp($(ele));
    })
  }

  if($('.elective-listing').length) {
    $('.elective-listing').map((i, ele) => {
      new ElectiveListing($(ele));
    })
  }

  if($('.event-listing').length) {
    $('.event-listing').map((i, ele) => {
      new EventListing($(ele));
    })
  }

  if($('.happenings-listing').length) {
    $('.happenings-listing').map((i, ele) => {
      new HappeningsListing($(ele));
    })
  }

  // custom select to be init after all listings because it emits events that sets values
  if ($('.custom-select').length) {
    $('.custom-select').map((i, ele) => {
      new CustomSelect($(ele));
    });
  }

  if ($('.accordion-group').length) {
    $('.accordion-group').map((i, ele) => {
      new AccordionGroup($(ele));
    });
  }

  if ($('.accordion').length) {
    $('.accordion').map((i, ele) => {
      const accordion = new Accordion($(ele));

      if(!$(ele).prev().hasClass('accordion')) {
        // this means this is the first accordion of a cluster of accordions
        accordion.open();
      }
    });
  }

  if ($(".content-tabs").length) {
    $(".content-tabs").map((i, ele) => {
      new TabsRteRevamp($(ele));
    });
  }

  if ($('.video-section').length) {
    $('.video-section').map((i, ele) => {
      new VideoSection($(ele));
    });
  }

  if ($(".video-player").length) {
    const iframeAPI = "https://www.youtube.com/iframe_api";

    if (!$('script[src="' + iframeAPI + '"]').length) {
      const firstScriptTag = document.getElementsByTagName("script")[0];
      const tag = document.createElement("script");
      tag.src = iframeAPI;
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    const players = [];
    const videoPlayers = [];

    $(".video-player").map((i, video) => {
      if ($(".video-player__holder", $(video)).length) {
        const playerData = {
          id: $(video).find(".video-player__holder").attr("id"),
          videoId: $(video).data("videoId"),
          YTPlayer: null,
        };
        players.push(playerData);
  
        const videoPlayerInstance = new VideoPlayer($(video), true, playerData);
        videoPlayers.push(videoPlayerInstance);
      }
    });

    window.onYouTubeIframeAPIReady = () => {
      players.map((player, i) => {
        player.YTPlayer = new YT.Player(player.id, {
          videoId: player.videoId,
          events: {
            onReady: (e) => {
              videoPlayers[i].onReady($(".video-player").eq(i));
            },
          },
        });
      });
    };
  }

  if ($(".tabbed-tables").length) {
    $(".tabbed-tables").map((i, ele) => {
      new TabbedTables($(ele));
    });
  }

  if ($(".three-cards-section").length) {
    $(".three-cards-section").map((i, ele) => {
      new ThreeCardSection($(ele));
    });
  }

  new ModalHandler();

  // if ($('.nav-dropdown').length) {
  //   $('.nav-dropdown').map((i, ele) => {
  //     const dropdown = new NavDropdown($(ele), false);
  //     dropdown.init();
  //   });
  // }

  // if (!$('.sfPageEditor').length && $('.accordion-item').length) {
  //   $('.accordion-item').map((i, ele) => {
  //     // Wrap consecutive accordions with .accordion
  //     $(ele).not('.accordion-item+.accordion-item').each(() => {
  //       $(ele).nextUntil(':not(.accordion-item)').addBack().wrapAll('<section class="accordion" data-total="0" data-count="0"/>');
  //     });
  //   });

  //   $('.accordion').map((i, ele) => {
  //     // const accordionConfig = {};
  //     const accordion = new Accordion($(ele), false);
  //     accordion.init($(ele));

  //     if($('.accordion-item', $(ele)).length > 1) {
  //       accordion.initExpandCollapse($(ele));
  //     }
  //   });
  // }

  // if (!$('.sfPageEditor').length && $('.tab-content').length) {
  //   $('.tab-content').map((i, ele) => {
  //     const tab = new TabContent($(ele), false);
  //     tab.init($(ele));
  //   });
  // }

  /**
   * END: Utility modules
   */

  if($('.event-timeline').length){
    $('.event-timeline').map((i, ele) => {
      new EventTimeline($(ele));
    });
	}

  if($('.school-courses-widget').length){
    $('.school-courses-widget').map((i, ele) => {
      new SchoolCoursesWidget($(ele));
    });
	}

  if($('.card-with-white-text').length){
    $('.card-with-white-text').map((i, ele) => {
      new CardWithWhiteText($(ele));
    });
	}

  if ($('.school-search').length) {
    $('.school-search').map((i, ele) => {
      new SchoolSearch($(ele));
    });
	}

  if ($('.sub-nav').length) {
    $('.sub-nav').map((i, ele) => {
      new SubNav($(ele));
    });
	}

  if ($('.hoverable-image-card').length) {
    $('.hoverable-image-card').map((i, ele) => {
      new HoverableImageCard($(ele));
    });
	}

  if ($('.testimonial-list').length) {
    $('.testimonial-list').map((i, ele) => {
      new TestimonialList($(ele));
    });
	}

  if ($('.expandable-hover-card').length) {
    $('.expandable-hover-card').map((i, ele) => {
      new ExpandableHoverCard($(ele));
    });
	}
});

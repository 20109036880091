'use strict';

export default class ListingFilter {
  constructor($selector, selfInit = true) {
    this.$selector = $selector;

    if(selfInit) this.init();
  }

  init() {
    $('.listing-filter__field--submit > button', this.$selector).on('click', (e) => {
      emitter.emit('fetchData');
    });

    $('.listing-filter__top > button', this.$selector).on('click', (e) => {
      e.preventDefault();

      if(!this.$selector.hasClass('expanded')) {
        this.$selector.addClass('expanded');
        $('.listing-filter__middle', this.$selector).slideDown(300);
      }
      else {
        this.$selector.removeClass('expanded');
        $('.listing-filter__middle', this.$selector).slideUp(300);
      }
    });
  }
}

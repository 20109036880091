'use strict';
import dot from 'dot';
import SelectionPill from '../../atoms/selection-pill/selection-pill';

export default class FilterSelectionList {
  constructor($selector, selfInit = true) {
    this.$selector = $selector;
    this.selectionPillTemplate = $('#dotDelectionPill').html();

    if(selfInit) this.init();
  }

  init() {
    emitter.on('filterSelected', (data) => {
      const _pillHTML = dot.template(this.selectionPillTemplate)(data);

      this.$selector.append(_pillHTML);

      $('.selection-pill button', this.$selector).off('click');

      $('.selection-pill', this.$selector).map((i, ele) => {
        new SelectionPill($(ele));
      });
    });

    emitter.on('filterRemoved', (data) => {
      this.$selector.find(`[data-value=${data.value}]`).remove();
    });

    emitter.on('selectionClear', (data) => {
      this.$selector.empty();
    });
  }
}

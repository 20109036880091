'use strict';

export default class SubNav {
  constructor($selector, selfInit = true) {
    this.$selector = $selector;

    if(selfInit) this.init($selector);
  }

  init($selector) {
    let $dropdownBtn = $('.sub-nav__selected', $selector);
    let defaultLabel = $dropdownBtn.data('default');
    
    $dropdownBtn.on('click', (e) => {
      this.$selector.toggleClass('expanded');
    });

    this.subNavOffsetTop = this.$selector.offset().top;

    /* console.log($(window).scrollTop(), (this.subNavOffsetTop - siteHeaderHeight));
    if ($(window).scrollTop() > (this.subNavOffsetTop - siteHeaderHeight)) {
      this.$selector.addClass('is-sticky');
    } */

    if (this.$selector.hasClass('anchor-nav')) {
      $('.sub-nav__list a', this.$selector).map((i, ele) => {
        const target = $(ele).attr("href");
        $(ele).on('click', (e) => {
          e.preventDefault();
          this.$selector.removeClass('expanded');
          
          if ($(target).length) {
            //const anchorNavoffset = isDesktop ? $anchorNav.outerHeight() : 46; // 46px is height in mobile
            $("html, body").animate({
              scrollTop: $(target).offset().top - this.$selector.outerHeight() - $(".site-header").outerHeight(),
            });
          }
        });
      });
    }

    let anchorStickPoint = this.$selector.outerHeight() + 30;
    let siteHeaderHeight = $('.site-header').outerHeight();
    let scrollTimeout;

    if ($(window).scrollTop() > (this.subNavOffsetTop - siteHeaderHeight)) {
      this.$selector.addClass('is-sticky');
      this.$selector.addClass('sticking');
    }
    $(window).on('scroll.subNav', (e) => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        if (!this.$selector.hasClass('is-sticky')) {
          this.$selector.addClass('is-sticky'); //Set sub-nav to position sticky
        }

        if ($(window).scrollTop() <= (this.subNavOffsetTop - siteHeaderHeight)) {
          if (this.$selector.hasClass('anchor-nav')) {
            //Set mobile dropdown button to default text when the scroll position is above the sub-nav
            $dropdownBtn.html(defaultLabel);
          }
          //.sticking class removed to indicate the sub-nav is not sticking at the top of screen
          this.$selector.removeClass('sticking');
        } else {
          //Add .sticking class to indicate sub-nav is sticking on top of screen
          if (!this.$selector.hasClass('sticking')) {
            this.$selector.addClass('sticking');
          }

          if (this.$selector.hasClass('anchor-nav')) {
            
            $('.sub-nav__list a', this.$selector).map((i, ele) => {
              const target = $(ele).attr("href");
              if ($(target).length) {
                
                if ($(window).scrollTop() >= $(target).offset().top - anchorStickPoint - siteHeaderHeight) {
                  $dropdownBtn.html($(ele).html());
                }
              }
            });
          } 
        }
      }, 200);
    });

    $(window).on('load.anchor', () => {
      const hash = window.location.hash;
      if ($(hash).length) {
        $("html, body").animate({
          scrollTop: $(hash).offset().top - this.$selector.outerHeight() - $(".site-header").outerHeight(),
        });
      }
    });

    let timer;
		$(window).on('resize', () => {
			clearTimeout(timer);

			timer = setTimeout(() => {
        //Get the original position of the subnav by scrolling to top
        let currentScrollPos = $(window).scrollTop();
        $(window).scrollTop(0);
				this.subNavOffsetTop = this.$selector.offset().top;

        //Scroll back to the previous position the user was in
        $(window).scrollTop(currentScrollPos)

        if ($(window).scrollTop() > (this.subNavOffsetTop - siteHeaderHeight)) {
          this.$selector.addClass('is-sticky');
          this.$selector.addClass('sticking');
        }
			}, 300);
		});
  }
}

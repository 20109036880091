'use strict';

import dot from "dot";

export default class NewsArticleListing {
  constructor($selector, selfInit = true) {
    this.$selector = $selector;
    this.endpoint = this.$selector.data('endpoint');
    this.$template = $('#dotNewsArticleList').html();
    if(selfInit) this.init();
  }

  init() {
    //Get the active tab and call API to render
    let $activeTab = $('.news-article-listing__body .active', this.$selector);
    this.data = { topic: $activeTab.data('topic')};
    
    const callback = (res, $activeTab) => {
      this._renderTemplate(res, $activeTab);
    }

    this.updateData(callback, $activeTab);

    $('nav a', this.$selector).map((i, ele) => {
      $(ele).on('click', (e) => {
        e.preventDefault();
        
        const target = $(ele).attr('href');

        if(!$(ele).hasClass('active')) {
          // remove other active
          $('nav a.active', this.$selector).removeClass('active');

          // make self active
          $(ele).addClass('active');

          if ($(`${target}`, this.$selector).is(':empty')) {
            this.data = { topic: $(`${target}`, this.$selector).data('topic')};
            this.updateData(callback, $(`${target}`, this.$selector));
          }          

          // remove active class from current active tab
          $(`.news-article-listing__body .active`, this.$selector).fadeOut(300, () => {
            $(`.news-article-listing__body .active`, this.$selector).removeClass('active');

            // set correct tab to active
            $(`${target}`, this.$selector).fadeIn(300, () => {
              $(`${target}`, this.$selector).addClass('active');
            });
          });
        }
      });
    });
  }

  updateData(callback, $activeTab) {
    $.ajax({
      url: this.endpoint,
      data: this.data,
      method: 'get',
      contentType: 'application/x-www-form-urlencoded',
      success: (res) => {
        //Get cta details and append to response
        res.ctaUrl = $activeTab.data("ctaurl");
        res.ctaLabel = $activeTab.data("ctalabel");
        callback(res, $activeTab);
      },
      error: (err) => {
        console.log(err);

        if (this.$selector.data("errormsg")) {
          $('.elective-listing__results__body', this.$selector).html(`<p class="empty-msg">${this.$selector.data("errormsg")}</p>`);
        }
      }
    });
  }

  _renderTemplate(data, $activeTab) {
    const dotTemplate = dot.template(this.$template)(data);

    $activeTab.html(dotTemplate);
  }
}

'use strict';

import $ from 'jquery';

export default class DiplomaCard {
	constructor($selector, selfInit = true) {
		this.$selector = $selector;

		if(selfInit) this.init();
	}

	init() {
		this.getContentHeight();

		// resize
		let timer;
		$(window).on('resize', () => {
			clearTimeout(timer);

			timer = setTimeout(() => {
				this.getContentHeight();
			}, 300);
		});
	}

	getContentHeight(){
		const nameHeight = this.$selector.find('.diploma-card__content__course-info__name').innerHeight();

		this.$selector.find('.diploma-card__content').css('height', `${40 + nameHeight}px`);
	}
}

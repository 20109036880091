'use strict';

export default class TestimonialList {
  constructor($selector, selfInit = true) {
    this.$selector = $selector;

    if(selfInit) this.init($selector);
  }

  init() {
    $(document).ready(()=> {
      this.updateCardPosition();
    });

    let timer;
    $(window).on("resize", (e) => {
      emitter.emit('closeExpandableHoverCard');
      this.updateCardPosition();
    });
  }

  updateCardPosition() {
    let $listContainer = $('.testimonial-list__body');
    let $cards = $listContainer.find('.expandable-hover-card');
    let containerWidth = $listContainer.width();
    let cardWidth = $cards.outerWidth(true);
    let cardHeight = $cards.outerHeight(true);

    let cardsPerRow = containerWidth < 1014 ? 2 : 5; //2 cards per row for mobile, 5 for desktop, 1014 = 1024px - 10px(padding)

    $cards.map((index, ele)=>{
      let row = Math.floor(index / cardsPerRow);
      let col = index % cardsPerRow;

      let top = row * cardHeight;
      let left = col * cardWidth;

      $(ele).css({
        'top': top + 'px',
        'left': left + 'px'
      });
      
    })

    // Adjust container height to fit all cards
    var totalRows = Math.ceil($cards.length / cardsPerRow);
    $listContainer.height(totalRows * cardHeight);
  }
}

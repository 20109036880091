'use strict';

import $ from 'jquery';
import 'slick-carousel';
import 'jquery-match-height';

export default class HappeningsCarousel {
	constructor($selector) {
		let $newsCarousel = $('.happenings-carousel__items-desktop', $selector);

		const arrowLeftPng = `<span class="icon icon-left-arrow"></span>`;
		const arrowRightPng = `<span class="icon icon-right-arrow"></span>`;
		let slidesToShow = 3;

		if ($('.happening-item', $newsCarousel).length < 3) {
			slidesToShow = $('.happening-item', $newsCarousel).length;
		}
		
		$newsCarousel.slick({
			speed: 700,
			swipe: true,
			slidesToShow: slidesToShow,
			slidesToScroll: 1,
			prevArrow: `<button class="slick-prev carousel-btn carousel-btn__prev" type="button">${arrowLeftPng}<span class="sr-only">navigation button</span></button>`,
			nextArrow: `<button class="slick-next carousel-btn carousel-btn__next" type="button">${arrowRightPng}<span class="sr-only">navigation button</span></button>`,
			infinite: true,
			centerMode: false,
			responsive: [
				{
					breakpoint: 910,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
	}
}

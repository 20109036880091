'use strict';

import $ from 'jquery';

export default class SchoolCard {
	constructor($selector) {
		let $schoolCardFirstLine = $('.school-card__first-line', $selector),
		 		$schoolCardSecondLine = $('.school-card__second-line', $selector);

		getContentHeight();

		// resize
		$(window).on('resize', () => {
			getContentHeight();
		})

    function getContentHeight(){
			if ($(window).width() > 768){
				$selector.each(function() {
					let schoolCardSecondLineHeight = $(this).find($schoolCardSecondLine).outerHeight();
					let flexGapHeight = 20;
					$(this).find($schoolCardFirstLine).css('transform', `translateY(${schoolCardSecondLineHeight + flexGapHeight}px)`);
					$(this).find($schoolCardSecondLine).css('transform', `translateY(${schoolCardSecondLineHeight + flexGapHeight}px)`);
				})
			}
		}
	}
}

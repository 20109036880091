'use strict';

export default class SelectionPill {
  constructor($selector, selfInit = true) {
    this.$selector = $selector;
    this.name = this.$selector.data('name');
    this.value = this.$selector.data('value');

    if(selfInit) this.init();
  }

  init() {
    $('button', this.$selector).on('click', (e) => {
      e.preventDefault();
      
      emitter.emit('selectionRemoved', {
        name: this.name,
        value: this.value
      });
      
      this.$selector.remove();
    });
  }
}

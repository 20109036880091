'use strict';

import $ from 'jquery';
import 'slick-carousel';
import 'jquery-match-height';

export default class NewTestimonialWidget {
	constructor($selector) {
		let $newTestimonialItem = $('.new-testimonial-item', $selector),
				$newTestimonialWidgetCarousel = $('.new-testimonial-widget__items', $selector),
    		$extraCarousel = $('.extra-carousel', $selector);

		const arrowLeftPng = `<span class="icon icon-left-arrow"></span>`;
		const arrowRightPng = `<span class="icon icon-right-arrow"></span>`;

		$newTestimonialWidgetCarousel.slick({
			speed: 400,
			prevArrow: `<button class="slick-prev carousel-btn carousel-btn__prev" type="button">${arrowLeftPng}<span class="sr-only">navigation button</span></button>`,
			nextArrow: `<button class="slick-next carousel-btn carousel-btn__next" type="button">${arrowRightPng}<span class="sr-only">navigation button</span></button>`,
			infinite: true,
			asNavFor: $extraCarousel,
			centerMode: true,
			fade: true,
			cssEase: 'linear',
			slidesToShow: 1,
			slidesToScroll: 1,
		});

		$extraCarousel.slick({
			speed: 50,
			slidesToShow: 2,
			slidesToScroll: 1,
			prevArrow: `<button class="slick-prev carousel-btn carousel-btn__prev" type="button">${arrowLeftPng}<span class="sr-only">navigation button</span></button>`,
			nextArrow: `<button class="slick-next carousel-btn carousel-btn__next" type="button">${arrowRightPng}<span class="sr-only">navigation button</span></button>`,
			infinite: true,
			arrows: false,
			adaptiveHeight: true,
			variableWidth: true,
			focusOnSelect: true,
			asNavFor: $newTestimonialWidgetCarousel,
			// responsive: [
			// 	{
			// 		breakpoint: 768,
			// 		settings: {
			// 		centerMode: false,
			// 		variableWidth: false,
			// 		slidesToShow: 1,
			// 		slidesToScroll: 1
			// 		}
			// 	},
			// 	{
			// 		breakpoint: 560,
			// 		settings: {
			// 			slidesToShow: 1,
			// 			slidesToScroll: 1
			// 		}
			// 	}
			// ]
		});

		// multiple carousel fade function doesn't work. mimic fade out and in instead
		$(".slick-next", $newTestimonialWidgetCarousel).on('click',function(){
			$(".slick-track", $extraCarousel).fadeOut(20);
			$(".slick-track", $extraCarousel).fadeIn(300);
		});
		$(".slick-prev", $newTestimonialWidgetCarousel).on('click',function(){
			$(".slick-track", $extraCarousel).fadeOut(20);
			$(".slick-track", $extraCarousel).fadeIn(300);
		});
		$(".new-testimonial-item-extra", $extraCarousel).on('click',function(){
			$(".slick-track", $extraCarousel).fadeOut(20);
			$(".slick-track", $extraCarousel).fadeIn(300);
		});


		$newTestimonialItem.matchHeight();

		$(window).on("resize", () => {
			// smoothen transition when resizes
			$newTestimonialWidgetCarousel.slick("refresh");
    });
	}
}

'use strict';

import $ from 'jquery';

export default class Pagination {
  constructor($selector) {
    let $list = $('.pagination__list', $selector);

    this.totalPages = 1;
    this.page = 1;
    // this.pageUrl = window.location.href;

    // let queries = window.location.search;
    // if (this.pageUrl.indexOf('?page=') !== -1 || this.pageUrl.indexOf('&page=') !== -1) {

    // }

    this.$list = $list;
  }

  update(page, totalPages, callback) {
    this.totalPages = totalPages;
    this.page = page;

    this._render(callback);
  }

  _render(callback = () => {}) {
    const totalPages = this.totalPages;
    const page = parseInt(this.page);

    if (totalPages < 2) {
      this.$list.html('');
      return;
    }

    const centerPadding = 1;
    const paginationSize = centerPadding * 2 + 1;
    let _html = '';
    // if (page > 1) {
    //   _html += `
    //   <li class="prev-page">
    //     <a href="#", title="Previous page" data-page="${ page - 1 }"><span class="icon icon-chevron-left"></span></a>
    //   </li>
    //   `;
    // }

    _html += `
    <li class="${ (page == 1) ? 'active' : ''}">
      <a href="#" title="Page 1" data-page="1">1</a>
    </li>
    `;

    let start = (page - centerPadding >= 2) ? page - centerPadding : 2;
    let end = (page + centerPadding >= totalPages - 1) ? totalPages - 1 : page + centerPadding;

    // to make sure the pagination size is retained when current page is at extreme end
    if (totalPages - page < centerPadding) {
      start = (page - (paginationSize - (totalPages - page)) + 1 > 2) ? page - (paginationSize - (totalPages - page)) + 1 : 2;
    }

    if (page - 1 < centerPadding) {
      end = (page + (paginationSize - (page - 1)) - 1 < totalPages - 1) ? page + (paginationSize - (page - 1)) - 1 : totalPages - 1;
    }

    if (page - centerPadding > 2 && start > 2) {
      _html += `
      <li class="ellipsis">
        <span>...</span>
      </li>
      `;
    }

    let i = start;
    while (i <= end) {
      _html += `
      <li class="${ (i == page) ? 'active' : ''}">
        <a href="#" title="Page ${i}" data-page="${i}">${ i }</a>
      </li>
      `;
      i++;
    }

    if (page + centerPadding < totalPages - 1 && end < totalPages - 1) {
      _html += `
      <li class="ellipsis">
        <span>...</span>
      </li>
      `
    }

    _html += `
    <li class="${ (page == totalPages) ? 'active' : ''}">
      <a href="#" title="${ totalPages }" data-page="${ totalPages }">${ totalPages }</a>
    </li>
    `;

    // if (page < totalPages) {
    //   _html += `
    //   <li class="next-page">
    //     <a href="#" title="Next page" data-page="${ page + 1 }"><span class="icon icon-chevron-right"></span></a>
    //   </li>
    //   `;
    //   // _html += `
    //   // <li class="last-page">
    //   //   <a href="#" title="Last page" data-page="${ totalPages }"><span class="icon icon-arrow-right"></span></a>
    //   // </li>
    //   // `;
    // }

    this.$list.html(_html);

    callback();
  }

  init(totalPage, activePage, callback = null) {
    $('.pagination__list').empty();

    for (var i = 0; i < totalPage; i++) {
      $('.pagination__list').append('<li class="pagination__item"><span data-page=' + (i + 1) + ' class="pagination__link"><span>' + (i + 1) + '</span></span></li>');
    }
    this.setActivePage(activePage);
    this.updatePageNumber(totalPage, activePage);
  }

  setActivePage(activePage) {
    $('.pagination__list .pagination__link').parent().removeClass('active');
    $('.pagination__list .pagination__link[data-page=' + activePage + ']').parent().addClass('active');
  }

  updatePageNumber(totalPage, currentPage) {
    // if currentPage + 2 is less or equal than total
    // move one number forward

    const $pagination = $('.pagination'),
        $container = $('.pagination__list');
    const targets = $('.pagination__item', $container);

    $(targets).each(function (i, item) {
      i = i + 1;
      if (i < currentPage - 2) {
        if (i - 2 < 1) {
          //make sure always shows max 5 pagess
          $(item).hide();
        }
      } else if (i > currentPage + 2) {
        if (i + 2 > totalPage) {
          //make sure always shows max 5 pages
          $(item).hide();
        }
      }
    });

    if (currentPage <= 1) {
      $('.js-prev-page', $pagination).addClass('is-hidden');
    } else {
      $('.js-prev-page', $pagination).removeClass('is-hidden');
    }

    if (totalPage - currentPage >= 1) {
      $('.js-next-page', $pagination).removeClass('is-hidden');
    } else {
      $('.js-next-page', $pagination).addClass('is-hidden');
    }

    // if currentPage + 2 is less than 0
  }
}

'use strict';

export default class CardWithWhiteText {
  constructor($selector, selfInit = true) {
    this.$selector = $selector;

    if(selfInit) this.init();
  }

  init() {
    this.getContentHeight();

		// resize
		let timer;
		$(window).on('resize', () => {
			clearTimeout(timer);

			timer = setTimeout(() => {
				this.getContentHeight();
			}, 300);
		});
  }

  getContentHeight(){
		const titleHeight = this.$selector.find('h3').height();

		this.$selector.find('.card-with-white-text__text').css('height', `${40 + titleHeight}px`);
	}
}

'use strict';

export default class VideoPlayer {
  constructor($selector, selfInit = true, playerData) {
    this.$selector = $selector;

    if(selfInit) this.init(playerData);
  }

  init(playerData) {
    $('button', this.$selector).on('click', (e) => {
      e.preventDefault();

      playerData.YTPlayer.playVideo();
      $('button', this.$selector).hide();
      this.$selector.addClass("video-play");
    });
  }

  onReady($video) {
    $video.addClass("video-ready");
  }
}

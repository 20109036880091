'use strict';

export default class SchoolSearch {
  constructor($selector, selfInit = true) {
    if(selfInit) this.init($selector);
  }

  init($selector) {
    const $submitBtn = $('button[type="submit"]', $selector);
    let $selectField = $('select', $selector);

    $submitBtn.on('click', (e) => {
      e.preventDefault();

      window.open($selectField.val(), "_self");
    });
  }
}

'use strict';

import 'slick-carousel';

export default class Carousel {
  constructor($selector, selfInit = true, initSlick, before, after) {
		this.$selector = $selector;
    if(selfInit) this.init(initSlick, before, after);
  }

  init(init = () => {}, before = (e, slick, currentSlide, nextSlide) => {}, after = (e, slick, currentSlide) => {}) {
		const $arrows = this.$selector.parent().find('[data-carousel-arrows]').length ? this.$selector.parent().find('[data-carousel-arrows]') : this.$selector;

		let options = {};

		const arrowLeftPng = `<span class="icon-left-arrow"></span>`;
		const arrowRightPng = `<span class="icon-right-arrow"></span>`;

		const defaultOptions = {
			rows: 0,
			prevArrow: `<button type="button" class="carousel-btn slick-prev slick-arrow"><span class="visuallyHidden">Previous</span>${arrowLeftPng}</button>`,
      nextArrow: `<button type="button" class="carousel-btn slick-next slick-arrow"><span class="visuallyHidden">Next</span>${arrowRightPng}</button>`,
			appendArrows: $arrows
		};
    
		options = { ...defaultOptions, ...this.$selector.data('options') };

		if(this.$selector.parent().find('[data-carousel-dots]').length) {
			const $dots = this.$selector.parent().find('[data-carousel-dots]');
			const dotOption = {
				appendDots: $dots
			};

			options = { ...options, ...dotOption };
		}

		if(this.$selector.data('navfor')) {
			const $container = this.$selector.closest('section');
			const $navFor = $(`${this.$selector.data('navfor')}`, $container);

			const navForOption = {
				asNavFor: $navFor
			}

			options = { ...options, ...navForOption };
		}

		if(this.$selector.parent().find('[data-carousel-pager]').length) {
			this.$selector.on('init reInit', (event, slick, currentSlide, nextSlide) => {
				this.$selector.parent().find('[data-carousel-pager]').text(`${(currentSlide ? currentSlide : 0) + 1} / ${slick.slideCount}`);
			});
			this.$selector.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
				this.$selector.parent().find('[data-carousel-pager]').text(`${(nextSlide ? nextSlide : 0) + 1} / ${slick.slideCount}`);
			});
		}

		if(this.$selector.find('video').length) {
			this.$selector.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
				if($(`[data-slick-index=${currentSlide}]`, this.$selector).find('video').length) {
					$(`[data-slick-index=${currentSlide}]`, this.$selector).find('video')[0].pause();
				}

				if($(`[data-slick-index=${nextSlide}]`, this.$selector).find('video').length) {
					$(`[data-slick-index=${nextSlide}]`, this.$selector).find('video')[0].play();
				}
			});
		}

    this.$selector.on('init', (e, slick) => {
      this._onPrint(this.$selector);

			if($('.slick-current video', this.$selector).length) {
				$('.slick-current video', this.$selector)[0].play();
			}
			if (init) init();
    });

    this.$selector.slick(options);

    if (before) this.$selector.on('beforeChange', before);

    if (after) this.$selector.on('afterChange', after);
  }

  _onPrint($carousel){
		let beforePrint = function () {
			console.log('Functionality to run before printing.');
			$carousel.slick('setPosition');
		};

		let afterPrint = function () {
			console.log('Functionality to run after printing');
			$carousel.slick('setPosition');
		};

		if (window.matchMedia) {
			let mediaQueryList = window.matchMedia('print');

			mediaQueryList.addListener(function (mql) {
				if (mql.matches) {
					beforePrint();
				} else {
					afterPrint();
				}
			});
		}

		window.onbeforeprint = beforePrint;
		window.onafterprint = afterPrint;
	}

	setPosition() {
    this.$selector.slick("setPosition");
  }

  goToSlide(index) {
    this.$selector.slick("slickGoTo", index);
  }

  slickPause() {
    this.$selector.slick("slickPause");
  }

  slickPlay() {
    this.$selector.slick("slickPlay");
  }

  slickInit() {
    this.$selector.slick(this.fulloptions);
  }

  unslick() {
    this.$selector.slick("unslick");
  }
}

'use strict';

import Accordion from "../accordion/accordion";

export default class TabbedTables {
  constructor($selector, selfInit = true) {
    this.$selector = $selector;
    this.state = {
			isAnimating: false
		};
    this.$tabs = $('.tabbed-tables__nav', this.$selector);

    if(selfInit) this.init();
  }

  init() {
    $('.table-expandable', this.$selector).map((i, ele) => {
      const id = $(ele).attr('id');
      const label = $(ele).attr('data-tablabel');
      const labelId = $(ele).attr('aria-labelledby');

      const _tabHTML = `<div class="${i == 0 ? 'active': ''}"><a href="#${id}" id="${labelId}">${label}</a></div>`;

      $('.tabbed-tables__nav', this.$selector).append(_tabHTML);
    });

    this.$tabs.find('> div').map((i,ele) => {
      $(ele).find('a').on('click', (e) => {
        e.preventDefault();

        const target = $(ele).find('a').attr('href');

        if(!$(ele).hasClass('active') && !this.state.isAnimating) {
          $('.tabbed-tables__nav div', this.$selector).removeClass('active');
          $(ele).addClass('active');

          this.state.isAnimating = !this.state.isAnimating;

          $(`[aria-hidden="false"]`, this.$selector).fadeOut(300, () => {
            $(`[aria-hidden="false"]`, this.$selector).attr('aria-hidden', true);
            $(`${target}`, this.$selector).attr('aria-hidden', false);
            $(`${target}`, this.$selector).fadeIn(300, () => {
              this.state.isAnimating = !this.state.isAnimating;
            });
          });
        }
      });
    });

    $('.table-accordion', this.$selector).map((i, ele) => {
      new Accordion($(ele), '.table-accordion__content');
    });
  }
}
